const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        // Mix the value with 255 (white) and take a portion to get pastel color
        const pastelValue = Math.floor((value + 255) / 2); 
        color += `00${pastelValue.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}


const ColourFunction = (name) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default {ColourFunction, stringToColor}