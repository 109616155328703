import React, { useState, forwardRef, useImperativeHandle } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const NewDialog = forwardRef((props, ref) => {
    const { modalData, ...other } = props;
    const [openDialog, setOpen] = useState(false);
    const [modalRenderData, setModalRenderData] = useState(false);

    useImperativeHandle(ref, () => ({
        handleOpen(dialogObj) {
            setModalRenderData(dialogObj)
            setOpen(true)
        },
        modalCloseAction() {
            setOpen(false);
        },
        updateModal(dialogObj) {
            setModalRenderData(dialogObj)
        }
    }));

    const handleModalClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
            return;
        setOpen(false);
    };  

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleModalClose}
                fullWidth={true}
                maxWidth={modalRenderData.maxWidth ? modalRenderData.maxWidth:'md'}
            >
                <DialogTitle>{modalRenderData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {modalRenderData.context}
                    </DialogContentText>
                    {modalRenderData.components}
                </DialogContent>
                <DialogActions>
                    {
                        modalRenderData.buttons &&
                        (modalRenderData.buttons.bottom.map((item, index) => (<Button variant="contained" color={item.color ? item.color:"primary"} disabled={item.disable} key={index} onClick={item.onClick}>{item.label}</Button>)))
                    }
                    {
                        modalRenderData.okButton && modalRenderData.okButton === 1 &&
                        (<Button onClick={handleModalClose}>Ok</Button>)
                    }
                    {
                        modalRenderData.cancelButton && modalRenderData.cancelButton === 1 &&
                        (<Button color="error" onClick={handleModalClose}>{modalRenderData.cancelButtonLabel ? modalRenderData.cancelButtonLabel:"Cancel"}</Button>)
                    }
                </DialogActions>
            </Dialog>
        </>
    );
});

export default NewDialog;