import React, { useRef, useEffect, useState } from "react";
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


const DeleteUserIcon = (props) => {
    const { user, btnFunc,...others } = props;
    const snackRef = useRef();

    return (
        <>
            <IconButton
                aria-label="delete user"
                onClick={() => btnFunc(user)}
                sx={{
                    "&.Mui-selected": {
                        backgroundColor: "#cfc6e3"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#cfc6e3"
                    },
                    ":hover": {
                        backgroundColor: "#cfc6e3"
                    }
                }}>
                <DeleteIcon sx={{ color: '#523f7f' }} />
            </IconButton>
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default DeleteUserIcon;