import React, { useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AppsIcon from '@mui/icons-material/Apps';
import Logo from '../../assets/Tahola_Logo_transparent.png'
import OverlaySideNav from '../SideNav/OverlaySideNav';
import { useAuth0 } from '@auth0/auth0-react';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';


const settings = ['Logout'];

const TopNav = (props) => {
    const { selectedMenuItem, selectedFunc, adminGroup, display, ...others } = props;
    const { user, logout, isAuthenticated } = useAuth0();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const navRef = useRef();

    const openNav = () => {
        navRef.current.openDraw();
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar color="secondary" position="fixed" elevation={0} sx={{ borderBottom: 1, borderColor: "rgba(0, 0, 0, 0.12)", color: "#523f7f", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, display: display }}
                        onClick={openNav}                        
                    >
                        <AppsIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <a href='/'>
                            <img src={Logo} height={30} />
                        </a>
                    </Box>
                    {isAuthenticated && display=='flex' && (<Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user.name} src={user.picture} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {/* <MenuItem> */}
                            <Typography sx={{margin: '8px 1em 8px 1.3em'}} variant="caption">
                            {user.name}
                            </Typography>
                            {/* </MenuItem> */}
                            {settings.map((setting) => (
                                <MenuItem sx={{alignItems: 'center'}} key={setting} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                    <Typography sx={{marginRight:'5px'}} variant="caption" textAlign="center">{setting}</Typography>
                                    <LogoutIcon/>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>)}
                </Toolbar>
            </AppBar>
            <OverlaySideNav
                selectedMenuItem={selectedMenuItem}
                selectedFunc={selectedFunc}
                ref={navRef}
                adminGroup={adminGroup}
            />
        </Box>
    );
}

export default TopNav;