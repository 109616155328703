import React, { useRef, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackToConsole from "../BackToConsole/BackToConsole";
import AppCard from "../AppCard/AppCard";
import InventoryIcon from '@mui/icons-material/Inventory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StyledDivider from "../StyledDivider/StyledDivider";
import FileListing from "../FileListing/FileListing";
import xlsxIcon from '../../assets/xlsx_icon.png';
import csvIcon from '../../assets/csv_icon.png';
import axios from "axios";
import { uriConfig } from '../../data/uri';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';


const FileManagement = (props) => {
    const { authToken, ...others } = props;
    const [fileList, setFileList] = useState([]);
    const [fileListLoading, setFileListLoading] = useState([true]);
    const snackRef = useRef();

    const fetchFileList = () => {
        const url = uriConfig.url.getListFile;
        setFileListLoading(true)
        axios.get(url, { headers: { 'auth-authorization': 'Bearer ' + authToken } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "data loaded" })
                const newList = []
                for (let item of response.data) {
                    let fileExtension = item.FileName.split(".").pop();
                    let fileInfo = {
                        "FileName": item.FileName,
                        "FileSize": item.FileSize,
                        "FilePath": item.FilePath,
                        "LastModified": item.LastModified,
                        "metaData": item.metaData,
                        "icon": fileExtension === 'xlsx' ? xlsxIcon : csvIcon,
                    }
                    newList.push(fileInfo)
                }
                setFileList(newList)
                setFileListLoading(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
                setFileListLoading(false)
            });
    };

    const downloadTemplates = (fileName) => {
        const url = uriConfig.url.getTemplates;
        axios.get(url, { params: { 'filepath': fileName }, headers: { 'auth-authorization': 'Bearer ' + authToken }, responseType: 'blob' })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "File downloaded" })
                const blob = new Blob([response.data])
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    const iconStyle = { color: '#523f7f', fontSize: '55px', marginBottom: '-15px', marginTop: '-10px' }

    const templateFileCards = [
        {
            "name": "Business Drivers",
            "url": "/files",
            "filename":"Novus-ai Business Drivers.xlsx",
            "imagepath": <InventoryIcon sx={iconStyle} />,
            "imagealttext": "Business Drivers"
        },
        {
            "name": "Budget File",
            "url": "/files",
            "filename":"Novus-ai Budget Template YYYY.xlsx",
            "imagepath": <AccountBalanceIcon sx={iconStyle} />,
            "imagealttext": "For Budget File"
        },
        {
            "name": "Forecast File",
            "url": "/files",
            "filename":"Novus-ai Forecast Template YYYY.xlsx",
            "imagepath": <TrendingUpIcon sx={iconStyle} />,
            "imagealttext": "Forecast File "
        },
        {
            "name": "Stock Management File",
            "url": "/files",
            "filename":"Novus-ai Stock Management Template YYYY.xlsx",
            "imagepath": <FormatListNumberedRtlIcon sx={iconStyle} />,
            "imagealttext": "Stock Management File "
        }
    ];

    useEffect(() => {
        fetchFileList()
    }, []);

    return (
        <>
            <Toolbar />
            <Box ml='90px' >
                <Box sx={{
                    marginTop: '5vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography variant='body1' color="text.secondary" marginBottom={'20px'}>
                        <strong>File Management</strong>
                    </Typography>
                    <Box sx={{
                        width: '80%'
                    }}>
                        <section id="go-back">
                            <BackToConsole linkLocation="/managementconsole"/>
                        </section>
                        <section id="templates" style={{ marginBottom: '20px' }}>
                            <StyledDivider label='Templates' />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 2,
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: 250,
                                    height: 100,
                                }
                            }}>
                                {templateFileCards.map((dataObj, index) => {
                                    return (
                                        <AppCard
                                            dataObj={dataObj}
                                            newTab={1}
                                            muiIcon={dataObj.imagepath}
                                            index={index}
                                            key={index}
                                            clickFunc={downloadTemplates}
                                        />
                                    );
                                })}
                            </Box>
                        </section>
                        <section id='Files'>
                            <StyledDivider margin label='Current Files' />
                            <Box sx={{
                                textAlign: 'right'
                            }}>
                                <Link to={'/fileupload'} state={fileList} >
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload Files
                                    </Button>
                                </Link>
                            </Box>
                            {fileListLoading ? <>
                                <Skeleton variant="rectangular" height={100} animation="wave" sx={{marginBottom: '5px', marginTop: '10px'}}/>
                                <Skeleton variant="rectangular" height={100} animation="wave" sx={{marginBottom: '5px'}}/>
                                <Skeleton variant="rectangular" height={100} animation="wave" sx={{marginBottom: '5px'}}/>
                                <Skeleton variant="rectangular" height={100} animation="wave"/>
                            </> :
                            <FileListing list={fileList} authToken={authToken} />}                            
                        </section>
                        <Box>

                        </Box>
                    </Box>
                </Box>
            </Box >
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default FileManagement;