import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { uriConfig } from './data/uri';
import axios from 'axios';


const root = ReactDOM.createRoot(document.getElementById('root'));
<React.StrictMode>
    <App loadingError='loading' />
</React.StrictMode >
axios.get(uriConfig.url.getEnvironment)    
    .then(function (response) {
        //console.log("get environment")
        const REACT_APP_AUTH0_DOMAIN = response.data.auth0_domain;
        const REACT_APP_AUTH0_CLIENT_ID = response.data.client_id;
        const REACT_APP_AUTH0_AUDIENCE = response.data.auth0_audience;

        const providerConfig = {
            domain: REACT_APP_AUTH0_DOMAIN,
            clientId: REACT_APP_AUTH0_CLIENT_ID,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: REACT_APP_AUTH0_AUDIENCE,
                scope: "openid profile read:all email"
            },
        };
        root.render(
            <React.StrictMode>
                <Auth0Provider {...providerConfig}>
                    <App />
                </Auth0Provider>
            </React.StrictMode >
        );
    }).catch(function (error) {
        console.log(error);
        root.render(
            <React.StrictMode>
                <App loadingError={error} />
            </React.StrictMode >
        );
    });