import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import FileUploadSteps from '../FileUploadSteps/FileUploadSteps'
import BackToConsole from "../BackToConsole/BackToConsole";
import Typography from '@mui/material/Typography';
import StyledDivider from "../StyledDivider/StyledDivider";
import Paper from '@mui/material/Paper';
import { useLocation } from "react-router-dom";


const FileUpload = (props) => {
    const { authToken, ...others } = props
    const { state } = useLocation();

    return (
        <>
            <Toolbar />
            <Box ml='90px' >
                <Box sx={{
                    marginTop: '5vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography variant='body1' color="text.secondary" marginBottom={'20px'}>
                        <strong>Upload Files</strong>
                    </Typography>
                    <Box sx={{
                        width: '90%'
                    }}>
                        <section id="go-back">
                            <BackToConsole linkLocation="/files" label='Files'/>
                        </section>
                        <section id="steps">
                            <StyledDivider label='Upload Files' />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column', 
                                width: '100%'
                            }}>
                                <Paper elevation={2} sx={{ width: '600px', padding: '20px' }}>
                                    <FileUploadSteps list={state} authToken={authToken}/>
                                </Paper>

                            </Box>
                        </section>
                    </Box>
                </Box>
            </Box >
        </>
    );
}

export default FileUpload;