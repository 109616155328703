import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Alerts from "../Alert/Alert";
import BuildingImage from '../../assets/building_1.gif'
import { Typography } from "@mui/material";


const HoldingPage = (props) => {
    const { emailVerified, ...others } = props

    return (
        <Box ml='70px' sx={{ backgroundColor: '#f8f8f8' }}>
            <Toolbar />
            {!emailVerified && <Alerts alertMessage={"Please verify your email address. Check your Junk email if you have not received it yet"} />}
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                    <img src={BuildingImage} alt="Under development" style={{ width: 'auto', height: 'auto' }} />
                </Box>
                <Typography variant="subtitle2" gutterBottom>
                    Your account setup is currently underway. Should your account remain inactive beyond one business day following registration, please reach out to our dedicated <a href='mailto:support@tahola.com?subject=Support needed for Novus-AI.AI&body=How can we help you?'><strong>support</strong></a> team for assistance.
                </Typography>
            </Box>
        </Box >
    );
}

export default HoldingPage;