import React from "react";
import { styled } from '@mui/material/styles';
import { Divider } from "@mui/material";
import Chip from '@mui/material/Chip';


const StyledDivider = (props) => {
    const { label, ...others } = props
    const DividerStyle = styled(Divider)(({ }) => ({
        marginBottom: '10px',
        '&::before': {
            width: '0px',
            flex: '0 0 0px'
        }
    }));

    return (
        <DividerStyle textAlign="left">
            <Chip label={label} size="large" />
        </DividerStyle>
    );
}

export default StyledDivider;