// import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Typography from '@mui/material/Typography';
import QlikIcon from '../../assets/qlik-icon.png'
import SnowflakeIcon from '../../assets/snowflake-icon.png'
import JedoxIcon from '../../assets/jedox-icon.png'
import TCIcon from '../../assets/taholaconnect-icon.png'
import QalyptusIcon from '../../assets/qalyptus-icon.png'
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../Loader/Loader';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { uriConfig } from '../../data/uri';
import AppCard from '../AppCard/AppCard';
import TutorialQlik from '../TutorialQlik/TutorialQlik';
import Skeleton from '@mui/material/Skeleton';


const HomePage = (props) => {
    const { cardReloadStatusFunc, cardData, tokenCallerFunc, authToken, cardDataStatus, qlikTutorialStatusFunc, qlikTutorialData, qlikTutorialDataStatus, ...others } = props;
    const { user } = useAuth0();
    const snackRef = useRef();
    const [renderQlikTutorial, setRenderQlikTutorial] = useState(false)

    const fetchInfo = () => {
        const url = uriConfig.url.getUsersApps;
        axios.get(url, { params: { 'user_sub': user.sub }, headers: { 'auth-authorization': 'Bearer ' + authToken } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Card loaded" })
                cardReloadStatusFunc(response.data)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    useEffect(() => {
        if (cardDataStatus === 0) {
            fetchInfo();
        }
    }, [cardDataStatus]);

    useEffect(() => {
        const containsQlik = cardData.some(item => item.name === 'Novus-AI Analytics');
        setRenderQlikTutorial(containsQlik)
    }, [cardData]);

    return (
        <>
            <Toolbar />
            {/* {cardDataStatus === 0 && <Loader /> } */}
            <section id="user-application">
                <Box ml='90px'>
                    <Box sx={{
                        ml: 1,
                        mt: 3
                    }}>
                        <Typography variant='body1' color="text.secondary">
                            <strong>Applications</strong>
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gridTemplateColumns: { md: '1fr 1fr' },
                        gap: 2,
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: 250,
                            height: 100,
                        },
                    }} >{cardData.length === 0 && cardDataStatus === 0 && <>
                    <Skeleton variant="rectangular" width={250} height={100} animation="wave"/>
                    <Skeleton variant="rectangular" width={250} height={100} animation="wave"/>
                    <Skeleton variant="rectangular" width={250} height={100} animation="wave"/>
                    </>}
                        {cardData.length === 0 && cardDataStatus === 1 && <Box><Typography variant='caption'>You do not have access to any applications. Please contact Tahola Support</Typography></Box>}                        
                        {cardData.map((dataObj, index) => {
                            return (
                                <AppCard
                                    dataObj={dataObj}
                                    icon={dataObj.imagepath === 'QlikIcon' ? QlikIcon : dataObj.imagepath === 'SnowflakeIcon' ? SnowflakeIcon : dataObj.imagepath === 'JedoxIcon' ? JedoxIcon : dataObj.imagepath === 'TCIcon' ? TCIcon :  dataObj.imagepath === 'QalyptusIcon' ? QalyptusIcon : ''}
                                    index={index}
                                    key={index}
                                />
                            );
                        })}
                    </Box>

                </Box>
            </section>
            <section id="tutorials">
            {renderQlikTutorial &&
                <TutorialQlik
                    authToken={authToken}
                    qlikTutorialStatusFunc={qlikTutorialStatusFunc}
                    qlikTutorialData={qlikTutorialData}
                    qlikTutorialDataStatus={qlikTutorialDataStatus}
                />
            }
            </section>
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default HomePage;