import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import React, { useState, forwardRef, useImperativeHandle } from "react";
import MenuItems from './MenuItems';
import SideNavBottom from "../SideNavBottom/SideNavBottom";
import BottomMenuItems from '../SideNavBottom/BottomMenuItems'


const drawerWidth = 270;

const OverlaySideNav = forwardRef((props, ref) => {
    const { selectedMenuItem, selectedFunc, adminGroup, ...others } = props;

    const [navState, setNavState] = useState(false);

    useImperativeHandle(ref, () => ({
        openDraw() {
            setNavState(true);
        }
    }));

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setNavState(open);
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : drawerWidth }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <MenuItems
                selectedMenuItem={selectedMenuItem}
                selectedFunc={selectedFunc}
                iconMenu={false}
                adminGroup={adminGroup}
            />
        </Box>
    );

    return (
        <Drawer
            anchor='left'
            open={navState}
            onClose={toggleDrawer(false)}
            //BackdropProps={{ invisible: true }}
            ModalProps={{
                slotProps: { backdrop: { invisible: true } },
            }}
        >
            <Toolbar />
            {list('left')}
            {/* <SideNavBottom
                width={drawerWidth}
                bgColour='#fff'>
                <BottomMenuItems />
            </SideNavBottom> */}
        </Drawer>
    )
});

export default OverlaySideNav;