import React from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import UnderConstructionImage from '../../assets/under-constraction.png'


const TemplatePage = (props) => {
    const {logStatus, ...others} = props

    return (
        <Box ml='70px' >
            <Toolbar />
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={UnderConstructionImage} alt="Under development" style={{width: 'auto', height:'auto'}} />
            </Box>
        </Box >
    );
}

export default TemplatePage;