import React, { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import NewDialog from "../NewDialog/NewDialog";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


const VideoCard = (props) => {
    const { dataObj, ...others } = props
    const [image, setImage] = useState("");
    const dialogRef = useRef();

    const loadImage = (imageName) => {
        import(`../../assets/${imageName}`).then((image) => setImage(image.default));
    };

    const openDialog = (config) => {
        dialogRef.current.handleOpen(config);
    }

    const updateDialog = (config) => {
        dialogRef.current.updateModal(config)
    }

    const dialogConfig = {
        title: dataObj.title,
        components: <iframe width="100%" height="900vh" src={dataObj.url + "&autoplay=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        cancelButton: 1,
        cancelButtonLabel: "Close"
    };

    useEffect(() => {
        if (dataObj.internalimage) {
            loadImage(dataObj.imagepath)
        } else {
            setImage(dataObj.imagepath)
        }
    }, [dataObj]);

    return (
        <>
            <Card sx={{ width: 300 }}>
                <CardActionArea onClick={() => { openDialog(dialogConfig) }}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={image}
                        alt={dataObj.imagealttext}
                    />
                    <PlayCircleOutlineIcon fontSize='large' sx={{ color: '#fff', position: 'absolute', top: '19%', left: '42%' }}/>
                    <CardContent sx={{ height: 150 }}>
                        <Typography gutterBottom variant="body1" component="div">
                            {dataObj.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {dataObj.body}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <NewDialog ref={dialogRef} />
        </>
    );
}

export default VideoCard;