import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const LinkCard = (props) => {
    const { dataObj, ...others } = props
    const [image, setImage] = useState("");

    const navigateUrl = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <Card sx={{ width: 300 }}>
            <CardContent>
                <Typography variant="button" display="block" color="text.secondary" gutterBottom>
                    {dataObj && dataObj.header ? dataObj.header: null}
                </Typography>
                <Typography variant="body1" component="div" color="#523f7f">
                    {dataObj && dataObj.title ? dataObj.title: null}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {dataObj && dataObj.subtitle ? dataObj.subtitle: null}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {dataObj && dataObj.message ? dataObj.message: null}
                </Typography>
            </CardContent>
            <CardActions onClick={() => navigateUrl(dataObj && dataObj.link ? dataObj.link: null) }>
                <Button size="small">{dataObj && dataObj.btn ? dataObj.btn: null}</Button>
            </CardActions>
        </Card>
    );
}


export default LinkCard;