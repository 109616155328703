import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from "@mui/material";
import AnimatedBarChart from "../AnimatedBarChart/AnimatedBarChart";
import { uriConfig } from '../../data/uri';
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const RegistrationPage = (props) => {
    const { ...others } = props
    const [param, setParam] = useState([]);
    const [auth0State, setAuth0State] = useState(null);
    const [auth0Token, setAuth0Token] = useState(null);
    const [firstRun, setFirstRun] = useState(0);
    const navigate = useNavigate();

    const getParameters = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const parameterObject = Object.fromEntries(urlSearchParams.entries());
        let queryOptions = []
        for (var key in parameterObject) {
            if (!parameterObject.hasOwnProperty(key)) continue;
            let parameterKey = key;
            let parameterValue = parameterObject[key];
            let newParameterObject = {
                name: parameterKey,
                value: parameterValue
            };
            queryOptions.push(newParameterObject);
            if (parameterKey === 'session_token') {
                setAuth0Token(parameterValue) 
            }
            else if (parameterKey === 'state') {
                setAuth0State(parameterValue) 
            }
        };
        setParam(queryOptions);
        setFirstRun(1);
    }

    const checkMissingKeys = (data, requiredKeys) => {
        const missingKeys = requiredKeys.filter(key => !data.some(item => item.name === key));
        if (missingKeys.length > 0) {
            return { "err": `missing ${missingKeys.join(', ')}` };
        }
        return 0;
    };
    
    const createNewUser = () => {
        const url = uriConfig.url.postNewUser;
        let data = {'token': auth0Token}
        axios.post(url, data)
            .then(function (response) {
                // handle success
                //console.log(response);
                window.location.href = response.data.continue_uri+'?state='+auth0State;
                //snackRef.current.snackBarOpen({ 'message': response.data })
            }).catch(function (error) {
                // handle error
                //snackRef.current.snackBarOpen({ 'message': error })
                const errorMessage = error.message;
                const errorCode = error.code;
                const errorReturnedMessage = error.response.data;
                navigate('/error', { 'state': {'Error Code': errorCode, 'Error Message':errorMessage, 'Error info':errorReturnedMessage }});
                console.log(error);
            });
    };

    useEffect(() => {
        getParameters();
    },[]);

    useEffect(() => {
        if (firstRun === 1) {
            const keys=['session_token', 'state'];
            const missingCheck = checkMissingKeys(param,keys);
    
            if(missingCheck != 0) {
                navigate('/error', { 'state': {'Error info':missingCheck.err }}); 
            } else{
                if(param.length === 2){
                    createNewUser();
                }                  
            }
        }        
    }, [param]);


    return (
        <Box>
            <Toolbar />

            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <AnimatedBarChart />
                <Typography variant="subtitle2" gutterBottom>
                    Your account setup is currently underway. Please wait...
                </Typography>
            </Box>
        </Box >
    );
}

export default RegistrationPage;