import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import NovusLoader from '../../assets/NovusLoader.gif';
import Paper from '@mui/material/Paper';


const Loader = () => {
    return (
        <Box ml='70px' >
            <Toolbar />
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {/* <CircularProgress /> */}
                <Paper square elevation={2}><img src={NovusLoader} alt="Loader" style={{width: 'auto', height:'100px'}} /></Paper>
            </Box>
        </Box >
    );
}

export default Loader;