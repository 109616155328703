import React, { useRef, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import BackToConsole from "../BackToConsole/BackToConsole";
import StyledDivider from "../StyledDivider/StyledDivider";
import UserListing from "../UserListing/UserListing";
import axios from "axios";
import { uriConfig } from '../../data/uri';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { useAuth0 } from '@auth0/auth0-react';
import Skeleton from '@mui/material/Skeleton';


const UserManagement = (props) => {
    const { authToken, ...others } = props;
    const { user } = useAuth0();
    const snackRef = useRef();
    const [userList, setUserList] = useState([]);
    const [userListLoading, setUserListLoading] = useState([true]);

    const fetchUsers = () => {
        const url = uriConfig.url.getUsers;
        setUserListLoading(true)
        axios.get(url, { params: { 'user_sub': user.sub }, headers: { 'auth-authorization': 'Bearer ' + authToken } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Users loaded" })
                const newUserList = []
                for (let item of response.data) {
                    let userInfo = {
                        "FullName": item.fullname,
                        "IsDeleted": item.isdeleted,
                        "user_sub": item.sub,
                        "Email": item.email,
                        "metaData": item.type,
                    }
                    newUserList.push(userInfo)
                }
                setUserList(newUserList)
                setUserListLoading(false)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                setUserListLoading(false)
                console.log(error);
            });
    };

    const deleteUser = (userSub) => {
        const url = uriConfig.url.postDeleteUser;
        let data = {'user_sub': userSub}
        axios.post(url, data, { headers: { 'auth-authorization': 'Bearer ' + authToken }, params: { 'user_sub': userSub } })
            .then(function (response) {
                // handle success
                fetchUsers()
                snackRef.current.snackBarOpen({ 'message': response.data })
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <>
            <Toolbar />
            <Box ml='90px' >
                <Box sx={{
                    marginTop: '5vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <Typography variant='body1' color="text.secondary" marginBottom={'20px'}>
                        <strong>User Management</strong>
                    </Typography>
                    <Box sx={{
                        width: '80%'
                    }}>
                        <section id="go-back">
                            <BackToConsole linkLocation="/managementconsole"/>
                        </section>
                        <section id='Current Users'>
                            <StyledDivider margin label='Current Users' />
                            <Box sx={{
                                textAlign: 'right'
                            }}>
                            </Box>
                            {userListLoading ? <>
                                <Skeleton variant="rectangular" height={100} animation="wave" sx={{marginBottom: '5px'}}/>
                                <Skeleton variant="rectangular" height={100} animation="wave"/>
                            </> :
                            <UserListing list={userList} authToken={authToken} showDeletedUsers={false} btnFunc={deleteUser} currentUserSub={user.sub} />}
                        </section>
                        <section id='Deleted Users' style={{marginTop: '20px'}}>
                            <StyledDivider margin label='Deleted Users' />
                            <Box sx={{
                                textAlign: 'right'
                            }}>
                            </Box>
                            {userListLoading ? <>
                                <Skeleton variant="rectangular" height={100} animation="wave" sx={{marginBottom: '5px'}}/>
                                <Skeleton variant="rectangular" height={100} animation="wave"/>
                            </> :
                            <UserListing list={userList} authToken={authToken} showDeletedUsers={true} noIcon={true} />}
                        </section>
                        <Box>

                        </Box>
                    </Box>
                </Box>
            </Box >
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default UserManagement;