import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HomeIcon from '@mui/icons-material/Home';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Link } from 'react-router-dom';


const MenuItems = (props) => {
    const { iconMenu, selectedMenuItem, selectedFunc, adminGroup, ...others } = props

    const handleListItemClick = (event, index) => {
        selectedFunc(index);
    };

    const linkStyle = {
        textDecoration: 'none',
        color: '#523f7f'
    };

    const selectedStyle = {
        borderLeft: '6px', 
        borderLeftStyle: 'solid', 
        borderColor: '#523f7f'
    }

    const selectedStyleNone = {
        borderLeft: '0px'
    }

    return (
        <>
            {iconMenu ? (
                <Box sx={{ width: '100%' }}>
                    <List sx={{ color: '#523f7f' }} >
                        <Link style={linkStyle} to="/home">
                            <ListItemIcon sx={{ paddingLeft: '9px' }} style={selectedMenuItem == 0 ? selectedStyle:selectedStyleNone}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={(event) => handleListItemClick(event, 0)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}>
                                    <HomeIcon sx={{ color: '#523f7f' }} />
                                </IconButton>
                            </ListItemIcon>
                        </Link>
                        {/* <Link style={linkStyle} to="/template">
                            <ListItemIcon sx={{ paddingLeft: '9px' }} style={selectedMenuItem == 1 ? selectedStyle:selectedStyleNone}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={(event) => handleListItemClick(event, 1)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}>
                                    <InboxIcon sx={{ color: '#523f7f' }} />
                                </IconButton>
                            </ListItemIcon>
                        </Link> */}
                        {adminGroup && 
                        <Link style={linkStyle} to="/managementconsole">
                            <ListItemIcon sx={{ paddingLeft: '9px' }} style={selectedMenuItem == 3 ? selectedStyle:selectedStyleNone}>
                                <IconButton
                                    aria-label="managementconsole"
                                    onClick={(event) => handleListItemClick(event, 3)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}>
                                    <SupervisorAccountIcon sx={{ color: '#523f7f' }} />
                                </IconButton>
                            </ListItemIcon>
                        </Link>}
                    </List>
                    <Divider />
                    <List>
                        <Link style={linkStyle} to="/contact">
                            <ListItemIcon sx={{ paddingLeft: '9px' }} style={selectedMenuItem == 2 ? selectedStyle:selectedStyleNone}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={(event) => handleListItemClick(event, 2)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}>
                                    <ContactSupportIcon sx={{ color: '#523f7f' }} />
                                </IconButton>
                            </ListItemIcon>
                        </Link>
                    </List>
                </Box>) : (<Box sx={{ width: '100%' }}>
                    <List sx={{ color: '#523f7f' }}>
                        <Link style={linkStyle} to="/home">
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={(event) => handleListItemClick(event, 0)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}
                                    style={selectedMenuItem == 0 ? selectedStyle:selectedStyleNone}>
                                    <ListItemIcon>
                                        <HomeIcon sx={{ color: '#523f7f' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        {/* <Link style={linkStyle} to="/template">
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={(event) => handleListItemClick(event, 1)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}
                                    style={selectedMenuItem == 1 ? selectedStyle:selectedStyleNone}>
                                    <ListItemIcon>
                                        <InboxIcon sx={{ color: '#523f7f' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Download Templates"} />
                                </ListItemButton>
                            </ListItem>
                        </Link> */}
                        {adminGroup && 
                        <Link style={linkStyle} to="/managementconsole">
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={(event) => handleListItemClick(event, 3)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}
                                    style={selectedMenuItem == 3 ? selectedStyle:selectedStyleNone}>
                                    <ListItemIcon>
                                        <SupervisorAccountIcon sx={{ color: '#523f7f' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Management Console"} />
                                </ListItemButton>
                            </ListItem>
                        </Link>}
                    </List>
                    <Divider />
                    <List sx={{ color: '#523f7f' }}>
                        <Link style={linkStyle} to="/contact">
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={(event) => handleListItemClick(event, 2)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        "&.Mui-focusVisible": {
                                            backgroundColor: "#cfc6e3"
                                        },
                                        ":hover": {
                                            backgroundColor: "#cfc6e3"
                                        }
                                    }}
                                    style={selectedMenuItem == 2 ? selectedStyle:selectedStyleNone}>
                                    <ListItemIcon>
                                        <ContactSupportIcon sx={{ color: '#523f7f' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Contact Support"} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    </List>
                </Box>)

            }
        </>
    )
}

export default MenuItems;