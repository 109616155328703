import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Stack } from '@mui/material';
import DeleteUserIcon from "../DeleteUserIcon/DeleteUserIcon";
import Colours from "../ColourFunction/ColourFunction";


const UserListing = (props) => {
    const { list, authToken, noIcon, showDeletedUsers, btnFunc, currentUserSub, ...others } = props

    const listItems =
        list.filter(item => item.IsDeleted == showDeletedUsers).map((item, index) =>
            <List key={index} sx={{ width: '100%', bgcolor: item.IsDeleted == false ? 'background.paper' : 'grey.200' }}>
                <ListItem key={index}>
                    <ListItemAvatar>
                        <Avatar {...Colours.ColourFunction(item.FullName)} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.FullName}
                    />
                    <Stack direction="row" spacing={2} sx={{ textAlign: 'right', marginRight: '2em' }}>
                        <Typography component='span' variant="caption" color="text.secondary">
                            {item.Email}
                        </Typography>
                    </Stack>
                    {
                        !noIcon && currentUserSub != item.user_sub && <ListItemIcon >
                            <DeleteUserIcon user={item.user_sub} authToken={authToken} btnFunc={btnFunc} />
                        </ListItemIcon>
                    }
                </ListItem>
                <Divider component="li" sx={{
                    marginBottom: '-8px'
                }} />
            </List>
        );

    return (
        <List>{listItems}</List>
    );
}
export default UserListing;