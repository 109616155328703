import React, { useRef, useEffect, useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { uriConfig } from '../../data/uri';
import axios from "axios";
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import IconButton from '@mui/material/IconButton';


const DownloadFileIcon = (props) => {
    const { authToken, downloadUri, ...others } = props;
    const snackRef = useRef();

    const fetchFile = (clientId,filePath,fileName) => {
        const url = uriConfig.url.getFile;
        axios.get(url, { params: { 'client': clientId, 'filepath': filePath }, headers: { 'auth-authorization': 'Bearer ' + authToken }, responseType: 'blob' })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "File downloaded" })
                const blob = new Blob([response.data])
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    return (
        <>
            <IconButton
                aria-label="download file"
                onClick={() => fetchFile('tor',downloadUri.FilePath, downloadUri.FileName)}
                sx={{
                    "&.Mui-selected": {
                        backgroundColor: "#cfc6e3"
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "#cfc6e3"
                    },
                    ":hover": {
                        backgroundColor: "#cfc6e3"
                    }
                }}>
                <DownloadIcon sx={{ color: '#523f7f' }} />
            </IconButton>
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default DownloadFileIcon;