import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';


const AppCard = (props) => {
    const { dataObj, icon, muiIcon, newTab, clickFunc, ...others } = props
    const linkStyle = {
        textDecoration: 'none',
        color: '#000000'
    };
    const cardContent = (
        <>
            <Box sx={{ maxWidth: 250, display: "flex", justifyContent: "center", alignItems: "center", pt: 2 }}>
                {icon && <img height="32" src={icon} alt={dataObj.imagealttext} />}
                {muiIcon}
            </Box>
            <CardContent>
                <Typography gutterBottom variant="subtitle2" component="div">
                    <strong>{dataObj.name}</strong>
                </Typography>
            </CardContent>
        </>
    );
    return (
        <Card sx={{ maxWidth: 250, textAlign: "center" }} onClick={clickFunc != null ? (e)=>clickFunc(dataObj.filename): null}>
            {
                newTab ? (
                    <CardActionArea component={Link} to={dataObj.url} style={linkStyle}>
                        {cardContent}
                    </CardActionArea>
                ) : (
                    <CardActionArea href={dataObj.url} target="_blank">
                        {cardContent}
                    </CardActionArea>

                )}

        </Card>
    );
};

export default AppCard;