import React from "react";
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';

const BackToConsole = (props) => {
    const { linkLocation, label, ...others } = props  

    return (
        <Link to={linkLocation}>
            <Button sx={{
                marginBottom: '10px',
                marginTop: '10px'
            }}
                component="label"
                tabIndex={-1}
                startIcon={<ArrowBackIosIcon />}
            >
                {label ? label: 'Management Console'}
            </Button>
        </Link>
    );
}

export default BackToConsole;