import React from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppCard from "../AppCard/AppCard";
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import TuneIcon from '@mui/icons-material/Tune';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography';


const ManagementConsole = (props) => {
    const { logStatus, ...others } = props
    const iconStyle = { color: '#523f7f', fontSize: '55px', marginBottom: '-15px', marginTop: '-10px' }
    const testCards = [
        {
            "name": "User Management",
            "url": "/usermanagement",
            "imagepath": <PersonIcon sx={iconStyle} />,
            "imagealttext": "For User Management"
        },
        {
            "name": "File Management",
            "url": "/files",
            "imagepath": <DescriptionIcon sx={iconStyle} />,
            "imagealttext": "For File Management"
        },
        {
            "name": "Portal Settings",
            "url": "/settings",
            "imagepath": <TuneIcon sx={iconStyle} />,
            "imagealttext": "For Portal Settings"
        }
        // ,
        // {
        //     "name": "Access Control",
        //     "url": "/access",
        //     "imagepath": <LockOpenIcon sx={iconStyle} />,
        //     "imagealttext": "Access Control"
        // }
    ];

    return (
        <>
            <Toolbar />
            <Box ml='90px' >
                <Box sx={{
                    marginTop: '20vh',
                    marginBottom: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>
                    <Typography variant='body1' color="text.secondary">
                        <strong>Management Console</strong>
                    </Typography>
                </Box>
                <Box sx={{
                    // marginTop: '20vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gridTemplateColumns: { md: '1fr 1fr' },
                    gap: 2,
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 250,
                        height: 100,
                    }
                }}>
                    {testCards.map((dataObj, index) => {
                        return (
                            <AppCard
                                dataObj={dataObj}
                                newTab={1}
                                muiIcon={dataObj.imagepath}
                                index={index}
                                key={index}
                            />
                        );
                    })}
                </Box>
            </Box >
        </>
    );
}

export default ManagementConsole;