import React, { useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import VideoCard from '../VideoCard/VideoCard';
import PopSnackBar from "../PopSnackBar/PopSnackBar";
import { uriConfig } from '../../data/uri';
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';
import LinkCard from '../LinkCard/LinkCard'


const TutorialQlik = (props) => {
    const { authToken, qlikTutorialStatusFunc, qlikTutorialData, qlikTutorialDataStatus, ...others } = props;

    const snackRef = useRef();

    const fetchQlikTutorials = () => {
        const url = uriConfig.url.getQlikTutorials;
        axios.get(url, { headers: { 'auth-authorization': 'Bearer ' + authToken } })
            .then(function (response) {
                // handle success
                snackRef.current.snackBarOpen({ 'message': "Qlik tutorial loaded" })
                qlikTutorialStatusFunc(response.data)
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    const qlikClassBusinessUser = {
        header: "Business user",
        title: "Continuous Classroom",
        message: "Explore Novus-AI continuous classrooms (Powered by Qlik). Gain access to interactive tutorials, hands-on exercises, and expert-led sessions to master your analytical skills in Novus-AI",
        btn: "Get started!",
        link: "https://learning.qlik.com/mod/page/view.php?id=25719"
    }

    const qlikClassDeveloper = {
        header: "Developer",
        title: "Continuous Classroom",
        message: "Explore Novus-AI continuous classrooms (Powered by Qlik). Learn how to create powerful, clean, cutting-edge visualizations, best ways to help users explore information and gain critical insights on multiple devices",
        btn: "Get started!",
        link: "https://learning.qlik.com/mod/page/view.php?id=24698"
    }

    useEffect(() => {
        if (qlikTutorialDataStatus === 0) {
            fetchQlikTutorials();
        }
    }, [qlikTutorialData]);

    return (
        <>
            <section id="qlik-tutorials">
                <Box ml='90px'>
                    <Box sx={{
                        ml: 1,
                        mt: 5
                    }}>
                        <Typography variant='body1' color="text.secondary">
                            <strong>Get started with Novus-AI (Powered by Qlik) </strong>
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gridTemplateColumns: { md: '1fr 1fr' },
                        gap: 2,
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }} >{qlikTutorialData.length === 0 && qlikTutorialDataStatus === 0 && <><Skeleton variant="rectangular" width={300} height={290} animation="wave"/><Skeleton variant="rectangular" width={300} height={290} animation="wave"/></>}
                        {qlikTutorialData.length === 0 && qlikTutorialDataStatus === 1 && <Box><Typography variant='caption'>There are no video tutorials available right now!</Typography></Box>}
                        {qlikTutorialDataStatus === 1 && <LinkCard dataObj={qlikClassBusinessUser}/> }
                        {qlikTutorialDataStatus === 1 && <LinkCard dataObj={qlikClassDeveloper}/> }
                        
                        {qlikTutorialData.sort((a, b) => a.orderno - b.orderno).map((dataObj, index) => {
                            return (                                
                                <VideoCard
                                    dataObj={dataObj}
                                    image={dataObj.imagepath}
                                    index={index}
                                    key={index}
                                />                                
                            );
                        })}
                    </Box>
                </Box>
            </section>
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default TutorialQlik;


