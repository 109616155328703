import React from "react";
import Box from '@mui/material/Box';
import "../../components/AnimatedBarChart/AnimatedBarChart.css"


const AnimatedBarChart = (props) => {
    const { ...others } = props

    return (
        <div className="icon">
            <span className="span"/>
            <span className="span"/>
            <span className="span"/>
        </div>
    );
}

export default AnimatedBarChart;