import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';


const ErrorPage = (props) => {
    const { ...others } = props

    const [err, setErr] = useState([])

    const { state } = useLocation();

    useEffect(() => {
        setErr(state)
    }, [state]);


    return (
        <Box ml='90px' >
            <Toolbar />
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h1" gutterBottom>
                    Oops!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Theres been an error!
                </Typography>
                {Object.keys(err).map((key) => (
                    <Typography key={key} variant="body2" color='error'>
                        <strong>{key}:</strong> {err[key]}
                    </Typography>
                ))}
            </Box>
        </Box >
    );
}

export default ErrorPage;