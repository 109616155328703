import React, { useRef, useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Stack } from '@mui/material';
import DownloadFileIcon from "../DownloadFileIcon/DownloadFileIcon";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Colour from "../ColourFunction/ColourFunction";


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const FileListing = (props) => {
    const { list, authToken, noIcon, ...others } = props

    const listItems = list.map((item, index) =>
        <List key={index} sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem key={index}>
                <ListItemAvatar>
                    <Avatar variant='square' alt={item.imagealttext} src={item.icon} />
                </ListItemAvatar>
                <ListItemText
                    primary={<React.Fragment>{item.FileName} <Chip label={item.FilePath.split("/")[0]}  size="small" variant="filled" sx={{background:Colour.stringToColor(item.FilePath.split("/")[0])}}/></React.Fragment>}
                    // secondary={ <Chip label={item.FilePath.split("/")[0]} color="primary" size="small" /> }
                />                
                {item.metaData && <HtmlTooltip title={
                    <React.Fragment>
                        <Stack>
                            <Typography component='span' variant="caption" color="text.secondary">
                                {"Notes:"}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography component='span' variant="caption" color="text.secondary">
                                {item.metaData.notes}
                            </Typography>
                        </Stack>
                    </React.Fragment>
                } placement="bottom"><ListItemIcon >
                        <InfoIcon />
                    </ListItemIcon></HtmlTooltip>}
                <Stack direction="row" spacing={2} sx={{ textAlign: 'right', marginRight: '2em' }}>
                    <Typography component='span' variant="caption" color="text.secondary">
                        {item.LastModified}
                    </Typography>
                    <Typography component='span' variant='caption' color="text.secondary">
                        {Math.round(item.FileSize / 1024)} kb
                    </Typography>
                </Stack>

                {!noIcon && <ListItemIcon >
                    <DownloadFileIcon downloadUri={item} authToken={authToken} />
                </ListItemIcon>}

            </ListItem>
            <Divider component="li" sx={{
                marginBottom: '-8px'
            }} />
        </List>
    );
    return (
        <List>{listItems}</List>
    );
}
export default FileListing;