import React from "react";
import Paper from '@mui/material/Paper';

const PoweredBy = (props) => {
    const {authToken, orgLogo, logoReload, setLogoFunc, ...others } = props

    return (
        <>
            <Paper elevation={0} sx={{ backgroundColor: 'transparent', color: 'text.secondary', fontSize: 10, fontWeight: 'medium', position: 'fixed', bottom: '10px', right: '10px' }}>Powered by Tahola™</Paper>
        </>
    );
}

export default PoweredBy;