import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import SideNavBottom from "../SideNavBottom/SideNavBottom";
import MenuItems from './MenuItems';
import BottomMenuItems from '../SideNavBottom/BottomMenuItems'

const drawerWidth = 65;

const SideIconNav = (props) => {
    const { selectedMenuItem, selectedFunc, adminGroup, display, ...others } = props

    return (
        <Box sx={{ display: display }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { backgroundColor:'#f0f0f0', width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto'}}>
                    <MenuItems 
                        selectedFunc={selectedFunc}
                        selectedMenuItem={selectedMenuItem}
                        iconMenu={true}
                        adminGroup={adminGroup} 
                    />
                </Box>
                {/* <SideNavBottom 
                    width={drawerWidth} 
                    bgColour='#f0f0f0'>
                    <BottomMenuItems iconMenu={true} />
                </SideNavBottom> */}
            </Drawer>
        </Box>
    );
}

export default SideIconNav;