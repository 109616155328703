import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const Alerts = (props) => {
    const { alertMessage, alertSeverity, alertVariant, ...others } = props

    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity={alertSeverity==null ? "warning": 'info'} variant={alertVariant==null ?  'standard':alertVariant}>{alertMessage}</Alert>
        </Stack>
    );
}

export default Alerts;