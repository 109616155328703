import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ContactUsImage from '../../assets/contact-us.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';


const ContactPage = (props) => {
    const { logStatus, ...others } = props

    return (
        <Box ml='70px' >
            <Toolbar />
            <Box style={{ height: '90vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                <Card sx={{ maxWidth: 345 }} size="small" color="primary">

                    <CardMedia
                        component="img"
                        height="240"
                        image={ContactUsImage}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Contact Tahola Support
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ paddingBottom: '30px' }}>
                            Experiencing difficulties or have a question regarding an existing Tahola product?
                        </Typography>
                        <CardActionArea>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', padding: '0px 0px 10px 0px' }} onClick={() => window.location = 'tel:08452578920'}>
                                <CallIcon />
                                <Typography sx={{marginLeft: '10px'}} variant="body2" color="text.secondary">
                                    0845 257 8920
                                </Typography>
                            </Box>
                        </CardActionArea>
                        <CardActionArea>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', padding: '0px 0px 10px 0px' }} onClick={() => window.location = 'mailto:support@tahola.com?subject=Support needed for Novus-AI&body=How can we help you?'}>
                                <MailOutlineIcon />
                                <Typography sx={{marginLeft: '10px'}} variant="body2" color="text.secondary">
                                    support@tahola.com
                                </Typography>
                            </Box>
                        </CardActionArea>



                    </CardContent>
                    {/* <CardActions sx={{ justifyContent: 'right' }}>
                        <Button>
                            <MailOutlineIcon />
                        </Button>
                    </CardActions> */}
                </Card>
            </Box>
        </Box >
    );
}

export default ContactPage;