import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import FileListing from '../FileListing/FileListing';
import xlsxIcon from '../../assets/xlsx_icon.png';
import csvIcon from '../../assets/csv_icon.png';
import Alerts from "../Alert/Alert";
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import Zoom from '@mui/material/Zoom';
import axios from 'axios';
import { uriConfig } from '../../data/uri';


const steps = ['Select file', 'Options', 'Upload'];

const fileType = [
    {
        value: 'business-driver',
        label: 'Business Driver',
    },
    {
        value: 'budgets',
        label: 'Budget',
    },
    {
        value: 'forecasts',
        label: 'Forecast',
    },
    {
        value: 'others',
        label: 'Other/bespoke',
    },
];

const validFileExtensions = ['xlsx', 'csv', 'xls']

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const FileUploadSteps = (props) => {
    const { list, authToken, ...others } = props

    const [activeStep, setActiveStep] = useState(0);
    const [fileInfo, setFileInfo] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [alert, setAlert] = useState(false);
    const [uploadDocType, setUploadDocType] = useState(null);
    const [overwriteFile, setOverwriteFile] = useState(null);
    const [nextButtonStatus, setNextButtonStatus] = useState(true);
    const [stepTwoAlertMessage, setStepTwoAlertMessage] = useState(null);
    const [fileNotes, setFileNotes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false)

    const newItem = {
        "FileName": "➕ Create new file",
        "FilePath": "new"
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNextButtonStatus(true)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const goBack = () => {
        window.history.go(-1)
    };

    const btnStatus = () => {
        if (activeStep === 0) {
            if (selectedFile !== null && uploadDocType !== null) {
                setNextButtonStatus(false)
            }
            else {
                setNextButtonStatus(true)
            }
        }
        if (activeStep === 1) {
            if (overwriteFile !== null) {
                setNextButtonStatus(false)
            }
            else {
                setNextButtonStatus(true)
            }
        }
    };

    const selectedDocType = (event) => {
        setUploadDocType(event.target.value)
    };

    const selectedOverwrite = (event) => {
        setOverwriteFile(event.target.value)
    };

    const setNotes = (event) => {
        setFileNotes(event.target.value)
    };

    const fileValidation = (e) => {
        if (e.target.value.length != 0) {
            let fileExtension = e.target.files[0].name.split(".").pop();
            if (validFileExtensions.includes(fileExtension)) {
                let fileInfo = [{
                    "FileName": e.target.files[0].name,
                    "FileSize": e.target.files[0].size,
                    "FilePath": "file to upload/"+e.target.files[0].name,
                    "LastModified": e.target.files[0].lastModifiedDate.toLocaleString(),
                    "icon": fileExtension === 'xlsx' ? xlsxIcon : csvIcon,
                }]
                setFileInfo(fileInfo)
                setSelectedFile(e.target.files[0])
                setAlert(false)
            }
            else {
                setFileInfo([])
                setSelectedFile(null)
                setAlert(true)
            }

        }
        else {
            setFileInfo([])
            setSelectedFile(null)
        }
    }

    const uploadFile = () => {
        const url = uriConfig.url.postFileUpload
        let formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('user_notes', fileNotes);
        formData.append('folder', uploadDocType);
        if(overwriteFile != '➕ Create new file') {
            formData.append('file_name', overwriteFile);
            formData.append('overwrite', true);
        }
        let data = formData
        const headers = {
            'auth-authorization': 'Bearer ' + authToken,
            'Content-Type': 'multipart/form-data'
        }
       
        setLoading(true)
        
        axios.post(url, data, { headers: headers})
            .then(function (response) {
                // handle success
                setUploadCompleted(true)
                //snackRef.current.snackBarOpen({ 'message': response.data })
            }).catch(function (error) {
                // handle error
                //snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };

    const stepOne = <Box sx={{ padding: '30px 20px', height: '85%' }}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    required
                    id="folder"
                    select
                    label="Select document type"
                    defaultValue=""
                    value={uploadDocType || ""}
                    helperText="Please select your document type"
                    fullWidth={true}
                    onChange={selectedDocType}
                >
                    {fileType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <Box sx={{ textAlign: 'center', margin: '20px 0px' }}>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Choose File
                    <VisuallyHiddenInput type="file" onChange={(e) => fileValidation(e)} />
                </Button>
            </Box>
            {alert && <Box sx={{ margin: '0px -7px 0 7px' }}><Alerts alertMessage={"Not a valid file type"} /></Box>}
            <FileListing list={fileInfo} noIcon={true} />
        </Box>
    </Box>

    const stepTwo = <Box sx={{ padding: '30px 20px', height: '85%' }}>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ marginBottom: '20px' }}>
                <TextField
                    required
                    id="overwrite"
                    select
                    label="Create new or replace existing"
                    defaultValue=""
                    value={overwriteFile || ""}
                    helperText="Please select a file to replace or create a new file"
                    fullWidth={true}
                    onChange={selectedOverwrite}
                >
                    {list.filter(item => item.FilePath.startsWith(uploadDocType)).concat(newItem).map((item, index) => (
                        <MenuItem key={index} value={item.FileName}>
                            {item.FileName != '➕ Create new file' ? 'Replace - ' + item.FileName : item.FileName}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="notes"
                    label="Notes"
                    multiline
                    rows={4}
                    value={fileNotes || ""}
                    onChange={setNotes}
                    fullWidth={true}
                    sx={{ marginBottom: '100px' }}
                />
            </div>
            {stepTwoAlertMessage && <Box sx={{ margin: '0px -7px 0 7px' }}><Alerts alertMessage={stepTwoAlertMessage} /></Box>}

        </Box>
    </Box>

    const stepThree = <Box sx={{ padding: '30px 20px', height: '85%' }}>

        {uploadCompleted != true && stepTwoAlertMessage && <Box sx={{ margin: '20px -7px 0px 7px' }}>
            <Alerts alertMessage={stepTwoAlertMessage} />
            <Box sx={{ margin: '20px' }}>Please press the Upload button below to start the upload or cancel to go back.</Box>
            <Box sx={{ textAlign: 'center', margin: '20px 0px' }}>
                <LoadingButton
                    size="small"
                    onClick={uploadFile}
                    endIcon={<CloudUploadIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                ><span>Upload</span></LoadingButton>
            </Box>
        </Box>}
        {uploadCompleted && <Box sx={{ textAlign: 'center', margin: '20px 0px' }}>
            <Zoom in={uploadCompleted} style={{ transitionDelay: uploadCompleted ? '0ms' : '0ms' }}>
                <VerifiedIcon color="primary" sx={{ fontSize: "200px" }} />
            </Zoom>
        </Box>}
    </Box>

    const navButton = <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
            color="inherit"
            disabled={activeStep === 0 || uploadCompleted}
            onClick={handleBack}
            sx={{ mr: 1 }}
        >
            Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep != steps.length - 1 && <Button onClick={handleNext} disabled={nextButtonStatus}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>}
        {activeStep === steps.length - 1 && uploadCompleted && <Button onClick={goBack}>
            Back to Files
        </Button>}
    </Box>

    useEffect(() => {
        btnStatus();
    }, [selectedFile, uploadDocType, overwriteFile, activeStep]);

    useEffect(() => {

        if (fileInfo.length > 0) {
            if (overwriteFile === "➕ Create new file") {
                setStepTwoAlertMessage(<>A new file will be created with the following name - <strong>{fileInfo[0].FileName}</strong></>)
            }
            else {
                setStepTwoAlertMessage(<>Following file will be overwritten - <strong>{overwriteFile}</strong> by <strong>{fileInfo[0].FileName}</strong></>)
            }
        }
    }, [overwriteFile]);


    return (
        <Box sx={{ marginTop: '20px', height: '450px', width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === 0 && (
                <React.Fragment>
                    {stepOne}
                    {navButton}
                </React.Fragment>
            )}
            {activeStep === 1 && (
                <React.Fragment>
                    {stepTwo}
                    {navButton}
                </React.Fragment>
            )}
            {activeStep === 2 && (
                <React.Fragment>
                    {stepThree}
                    {navButton}
                </React.Fragment>
            )}
        </Box>
    );
}


export default FileUploadSteps;