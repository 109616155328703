import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackToConsole from "../BackToConsole/BackToConsole";
import StyledDivider from "../StyledDivider/StyledDivider";
import Alerts from "../Alert/Alert";
import ImageIcon from '@mui/icons-material/Image';
import { uriConfig } from '../../data/uri';
import PopSnackBar from "../../components/PopSnackBar/PopSnackBar";
import OrgLogo from "../OrgLogo/OrgLogo";
import { Paper } from "@mui/material";
import axios from 'axios';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const validFileExtensions = ['jpg', 'jpeg', 'png']

const imageInfoMessage= <Box>Please make sure you image matches the following criteria - <br /><br />
Size should be no bigger than <strong>500kb</strong><br />
Resolution should be <strong>400x400 pixels</strong> or below<br />
Image format should be <strong>png/jpeg</strong>
</Box>

const PortalSettings = (props) => {
    const { authToken, orgLogo, setLogoFunc, ...others } = props;

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [uploadCompleted, setUploadCompleted] = useState(false)
    const [uploadBtnDisabled, setUploadBtnDisabled] = useState(true)

    const snackRef = useRef();

    const uploadLogo = () => {
        setUploadBtnDisabled(true)
        const url = uriConfig.url.postLogo
        let formData = new FormData();
        formData.append('logo', selectedLogo);        
        let data = formData
        const headers = {
            'auth-authorization': 'Bearer ' + authToken,
            'Content-Type': 'multipart/form-data'
        }
        
        axios.post(url, data, { headers: headers})
            .then(function (response) {
                // handle success
                setUploadCompleted(true)
                setLogoFunc(null,1)
                snackRef.current.snackBarOpen({ 'message': response.data })
            }).catch(function (error) {
                // handle error
                snackRef.current.snackBarOpen({ 'message': error })
                console.log(error);
            });
    };


    const fileValidation = (e) => {
        if (e.target.value.length != 0) {
            let fileExtension = e.target.files[0].name.split(".").pop();
            if (validFileExtensions.includes(fileExtension)) {
                if (e.target.files[0].size <= 500500) {
                    let width, height
                    let img = document.createElement("img");
                    img.onload = function () {
                        width = img.width
                        height = img.height
                        if (width <= 400 && height <= 400) {
                            setSelectedLogo(e.target.files[0])
                            setUploadBtnDisabled(false)
                            setLogoPreview(URL.createObjectURL(e.target.files[0]))
                            setAlert(false)
                        }
                        else {
                            setSelectedLogo(null)
                            setUploadBtnDisabled(true)
                            setLogoPreview(null)
                            setAlert(true)
                            setAlertMessage("Please make sure your logo is 400 x 400 or smaller")
                        }
                    };
                    img.src = URL.createObjectURL(e.target.files[0]);
                }
                else {
                    setSelectedLogo(null)
                    setUploadBtnDisabled(true)
                    setLogoPreview(null)
                    setAlert(true)
                    setAlertMessage("File size is too large. Please make sure the file is 500 KB or smaller in size")
                }
            }
            else {
                setSelectedLogo(null)
                setUploadBtnDisabled(true)
                setLogoPreview(null)
                setAlert(true)
                setAlertMessage("not a valid filetype, only accept jpeg/jpg or png image files")
            }
        }
        else {
            setSelectedLogo(null)
            setUploadBtnDisabled(true)
            setLogoPreview(null)
        }
    }


    return (
        <>
            <Toolbar />
            <Box ml='90px' >
                <Box sx={{
                    marginTop: '5vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography variant='body1' color="text.secondary" marginBottom={'20px'}>
                        <strong>Settings</strong>
                    </Typography>
                    <Box sx={{
                        width: '80%'
                    }}>
                        <section id="go-back">
                            <BackToConsole linkLocation="/managementconsole" />
                        </section>
                        <section id="company-logo">
                            <StyledDivider label='Company logo' />
                            <Box sx={{ margin: '10px' }}>
                            <Alerts alertSeverity={'info'} alertVariant={'outlined'} alertMessage={imageInfoMessage} />
                                <Paper sx={{
                                    width: '154px',
                                    height: '154px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginTop: '10px' 
                                }}>
                                    <OrgLogo base64={logoPreview ? false : true} orgLogo={logoPreview ? logoPreview : orgLogo} cssClass="logo-preview" authToken={authToken} logoReload={uploadCompleted === true ? 1:0} setLogoFunc={setLogoFunc} />
                                </Paper>
                            </Box>
                            {alert && <Box sx={{ margin: '0px 0px 0 10px' }}><Alerts alertMessage={alertMessage} /></Box>}
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<ImageIcon />}
                                sx={{ margin: '10px' }}
                            >
                                Select logo
                                <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={(e) => fileValidation(e)} />
                            </Button>
                            <Button
                                component="label"
                                role={undefined}
                                variant={selectedLogo !== null ? "contained" : "outlined"}
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                disabled={uploadBtnDisabled ?? true}
                                onClick={uploadLogo}
                            >
                                Upload
                            </Button>
                        </section>
                    </Box>
                </Box>
            </Box >
            <PopSnackBar ref={snackRef} />
        </>
    );
}

export default PortalSettings;